/** @jsx jsx */
import { Box, Container, Flex, Heading, jsx, Link, Text } from 'theme-ui'
import { useEffect } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { TypeAnimation } from '../components/TypeAnimation'
import DoubleArrows from '../assets/double-down-arrow.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faXingSquare } from '@fortawesome/free-brands-svg-icons'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const IndexPage = () => {
  const imgData = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "markus-sterner-circle.png" }) {
        childImageSharp {
          # Specify a fluid image and fragment
          # The default maxWidth is 800 pixels
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line global-require
      require('css-scroll-snap-polyfill')()
    }
  }, [])

  return (
    <Layout>
      <SEO title="Home"/>
      <div
        sx={{
          bg: 'black',
          maxHeight: '100vh',
          overflowY: 'scroll',
          scrollSnapType: 'y mandatory'
        }}
      >
        <Container
          sx={{
            variant: 'container.inverted',
            height: '100vh',
            scrollSnapAlign: 'start'
          }}
        >
          <Flex
            sx={{
              alignItems: 'center',
              flexDirection: 'column',
              paddingTop: '30vh'
            }}
          >
            <Heading as="h1" sx={{ textAlign: 'center' }}>
              Hi,
              <br/>I am Markus and
              <br/>
              <TypeAnimation/>
            </Heading>
            <DoubleArrows
              id="double-down-arrows"
              sx={{
                height: '3rem',
                marginTop: '30vh'
              }}
            />
          </Flex>
        </Container>
        <Container
          sx={{
            variant: 'container.inverted',
            height: '100vh',
            scrollSnapAlign: 'start'
          }}
        >
          <Flex
            sx={{
              alignContent: 'center',
              justifyContent: 'space-evenly',
              flexDirection: 'row',
              height: '100%',
              flexWrap: 'wrap-reverse'
            }}
          >
            <Box sx={{ marginX: 4 }}>
              <Heading
                as="h2"
                sx={{ fontSize: '2em', textAlign: 'center' }}
                mb={4}
              >
                Software Development
              </Heading>
              <Text
                as="p"
                sx={{
                  maxWidth: 500
                }}
              >
                Having worked many different jobs in my career, I have finally
                found my passion in 2014. During my studies of media informatics
                at LMU Munich, I wrote my first lines of computer code that
                sparked my curiosity, joy and enthusiasm for software
                development. Like a good code base, I have strived to continuously improve myself ever since.<br/>
                After working on numerous projects with diverse teams, I have gained more than just new technical
                skills. I am now proficient in comparing various approaches to software development and operations, as
                well as product and people management. In addition to my practical experience, I consistently acquire
                theoretical knowledge through books, blogs, newsletters, meetups, and workshops.
              </Text>
            </Box>
          </Flex>
        </Container>
        <Container
          sx={{
            variant: 'container.inverted',
            height: '100vh',
            scrollSnapAlign: 'start'
          }}
        >
          <Flex
            sx={{
              alignContent: 'center',
              justifyContent: 'space-evenly',
              flexDirection: 'row',
              height: '100%',
              flexWrap: 'wrap-reverse'
            }}
          >
            <Box sx={{ marginX: 4 }}>
              <Heading
                as="h2"
                sx={{ fontSize: '2em', textAlign: 'center' }}
                mb={4}
              >
                Entrepreneur
              </Heading>
              <Text
                as="p"
                sx={{
                  maxWidth: 500
                }}
              >
                In April 2021, I co-founded my first company (Nomizz) with a business partner, realizing my dream of
                becoming an entrepreneur. Over the course of a year, I had the opportunity to learn numerous invaluable
                lessons about running a company and developing a SaaS application from the ground up, serving in roles
                as CTO, product manager and engineer. Unfortunately I had to leave the company due to
                our inability to secure additional funding in March 2022.
                <br/>
                <br/>
                This was certainly not the last company that I will found ...
              </Text>
            </Box>
          </Flex>
        </Container>
        <Container
          sx={{
            variant: 'container.inverted',
            height: '100vh',
            scrollSnapAlign: 'start'
          }}
        >
          <Flex
            sx={{
              alignContent: 'center',
              justifyContent: 'space-evenly',
              flexDirection: 'row',
              height: '100%',
              flexWrap: 'wrap-reverse'
            }}
          >
            <Box sx={{ marginX: 4 }}>
              <Heading
                as="h2"
                sx={{ fontSize: '2em', textAlign: 'center' }}
                mb={4}
              >
                Need support?
              </Heading>
              <Text
                as="p"
                sx={{
                  maxWidth: 500
                }}
              >
                My greatest strength lies in bridging the gap between the business and engineering, all while personally
                coding for both the front-end and back-end. If you believe I can be of assistance to you and your team,
                please get in touch with me anytime :)
              </Text>
            </Box>
          </Flex>
        </Container>
        <Container
          sx={{
            variant: 'container.inverted',
            height: '100vh',
            scrollSnapAlign: 'start'
          }}
        >
          <Flex
            sx={{
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <Flex
              sx={{
                alignContent: 'center',
                justifyContent: 'space-evenly',
                flexDirection: 'row',
                height: '90%',
                flexWrap: 'wrap-reverse'
              }}
            >
              <Box sx={{ marginX: 4 }}>
                <Heading as="h2" sx={{ fontSize: '2em' }}>
                  Get in touch
                </Heading>
                <Heading as="h3" sx={{ marginTop: 4, marginBottom: 1, fontSize: '1.4rem' }}>
                  Markus Sterner
                </Heading>

                <Text sx={{ marginBottom: 3 }}>Software Developer &<br/>Entrepreneur</Text>
                <Link href="mailto:info@markus-sterner.de">
                  <FontAwesomeIcon icon={faEnvelopeSquare} size="2x"/>
                </Link>
                <Link
                  href="https://www.linkedin.com/in/markus-sterner-software-engineer"
                  target="_blank"
                  sx={{ marginLeft: 2 }}
                >
                  <FontAwesomeIcon icon={faLinkedin} size="2x"/>
                </Link>
                <Link
                  href="https://www.xing.com/profile/Markus_Sterner2/cv"
                  target="_blank"
                  sx={{ marginLeft: 2 }}
                >
                  <FontAwesomeIcon icon={faXingSquare} size="2x"/>
                </Link>
              </Box>
              <Box
                sx={{
                  height: 200,
                  width: 200,
                  marginX: 4
                }}
                mb={5}
              >
                <Img
                  fixed={imgData.file.childImageSharp.fixed}
                  alt="Markus Sterner Picture"
                  loading="eager"
                />
              </Box>
            </Flex>
            <footer sx={{ paddingBottom: 2 }}>
              <Link
                href="/imprint"
                sx={{
                  paddingLeft: 2,
                  color: '#707070',
                  textDecoration: 'none'
                }}
              >
                Imprint
              </Link>
            </footer>
          </Flex>
        </Container>
      </div>
    </Layout>
  )
}

export default IndexPage
